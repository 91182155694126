<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Par&aacute;metros</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <router-link to="/productos" class="text-500 no-underline"
        >Productos</router-link
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <router-link to="/laboratorios" class="text-900 line-height-3"
        >Marcas</router-link
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Marcas
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
      <div class="mt-3 lg:mt-0">
        <Button
          label="Nuevo"
          v-if="'Laboratorio Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-lg"
          icon="pi pi-plus"
          @click="openModal"
        ></Button>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtlaboratorios"
        :value="laboratorios"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 10, 25, 50, 100]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Laboratorios"
        responsiveLayout="scroll"
        :rowHover="true"
        showGridlines
        stripedRows
        @row-dblclick="editLaboratorio($event.data)"
        selectionMode="single"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>

        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="id" header="CÓDIGO" :sortable="true">
          {{ data.id }}
        </Column>
        <Column field="nombre_proveedor" header="PROVEEDOR" :sortable="true">
          {{ data.nombre_proveedor }}
        </Column>
        <Column field="nombre" header="MARCA" :sortable="true">
          {{ data.nombre }}
        </Column>
        <Column field="created_at" header="FECHA CREACIÓN">
          <template #body="{ data }">
            {{ formatFecha(data.created_at) }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado.value]">
              {{ data.estado.label }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      v-model:visible="deleteLabDialog"
      :style="{ width: '450px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="laboratorio"
          >Estas Seguro que quieres eliminar la Marca:
          <b>{{ laboratorio.nombre }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="No, Cancelar"
          icon="pi pi-times"
          class="p-button-success p-button-lg"
          @click="deleteLabDialog = false"
        />
        <Button
          label="Si Eliminar"
          icon="pi pi-check"
          class="p-button-danger p-button-lg"
          @click="deleteLab"
        />
      </template>
    </Dialog>

    <LaboratorioCreate
      :mostrar="nuevoRegistro"
      :laboratorio="laboratorio"
      @closeModal="closeModal"
      @actualizarListadoLaboratorio="buscarLaboratorios"
    ></LaboratorioCreate>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import LaboratorioCreate from "@/module/laboratorios/LaboratorioCreate.vue";
import { useAuth } from "@/stores";
import LaboratorioService from "@/service/LaboratorioService";

export default {
  components: {
    LaboratorioCreate,
  },
  data() {
    return {
      laboratorios: null,
      cargando: true,
      laboratorio: {},
      buscar: {},
      totalRegistros: 0,
      nuevoRegistro: false,
      deleteLabDialog: false,
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  auth: null,
  laboratorioService: null,
  created() {
    this.auth = useAuth();
    this.laboratorioService = new LaboratorioService();
    this.initBuscar();
  },
  mounted() {
    this.buscarLaboratorios();
  },
  methods: {
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Laboratorio Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editLaboratorio(datos);
          },
        },
        {
          label: "Ver Detalle",
          disabled:
            "Laboratorio Ver Detalle" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-eye",
          command: () => {
            this.verDetalle(datos);
          },
        },
        {
          label: "Eliminar",
          disabled:
            "Laboratorio Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.confirmDeleteLab(datos);
          },
        },
      ];
    },
    buscarLaboratorios() {
      this.laboratorioService.getAllLaboratorios().then((response) => {
        if (!response.autorizado && response.autorizado === false) {
          this.auth.userLogout();
        } else {
          this.laboratorios = response.fabricas;
          this.cargando = false;
          this.laboratorios.forEach((laboratorio) => {
            /*  laboratorio.created_at = new Date(laboratorio.created_at);
             laboratorio.updated_at = new Date(laboratorio.updated_at); */
            laboratorio.estado = {
              label: this.textoEstado(laboratorio.estado),
              value: laboratorio.estado,
            };
          });
        }
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.laboratorios.length; i++) {
        if (this.laboratorios[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    openModal() {
      this.laboratorio = {
        estado: { label: "Activo", value: 1 },
      };
      this.nuevoRegistro = true;
    },
    closeModal() {
      this.nuevoRegistro = false;
      this.laboratorio = {};
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
    editLaboratorio(info_laboratorio) {
      this.laboratorio = { ...info_laboratorio };
      this.nuevoRegistro = true;
    },
    confirmDeleteLab(laboratorio) {
      this.laboratorio = { ...laboratorio };
      this.deleteLabDialog = true;
    },
    deleteLab() {
      this.laboratorioService.deleteLab(this.laboratorio).then((response) => {
        if (response.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: response.mensaje,
            life: 3000,
          });
          this.deleteLabDialog = false;
          this.laboratorios = this.laboratorios.filter(
            (val) => val.id !== this.laboratorio.id
          );
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.mensaje,
            life: 3000,
          });
          this.deleteLabDialog = false;
        }
      });
    },
    formatFecha(fecha) {
      return new Date(fecha).toLocaleDateString("es-Es", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    verDetalle(laboratorio) {
      this.$router.push(`/laboratorio/${laboratorio.id}/detalle`);
    },
  },
  watch: {
    laboratorios() {
      this.totalRegistros = this.laboratorios.length;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
